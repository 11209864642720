export default {
  allEventsByZoneId: [],
  historicsEventsByZoneId: [],
  newEventsOnHold: false,
  expandTable: false,
  tag: "",
  eventsLoading: false,
  dataEvents: {},
  dataEventsHistoric: {},
  pagination: {
    pageNumber: 1,
    rowsPerPage: 25,
  },
  downloadingVideo: false,
  loadingTimelapse: false,
  videoSensorId: "",
  urlTimelapse: "",
  settingsVideoDownload: {},
  dateVideo: "",
  filterEventsToday: {
    priority: [],
    eventType: [],
    sensor: [],
    zones: [],
    status: []
  },
  filterEventsHistoric: {
    priority: [],
    eventType: [],
    sensor: [],
    zones: [],
    status: [],
    date: ""
  },
  counterAggregations: {},
  eventsTaken: [],
  alertSoundEvents: true,
  eventIdSelected: "",
  paginationChange: false,
  eventTypesByProject: []
};
