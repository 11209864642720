export default `
query AllSensorsByProject($clientId: String!, $projectId: String!, $filter:[DataFilterInput], $from:Int, $size:Int, $sort:DataQuerySortInput) {
  AllSensorsByProject(clientId: $clientId, projectId: $projectId, filter:$filter, from:$from, size:$size, sort:$sort) {
    total
    sensors{
      id
      displayName
      type
      geo{
        lat
        lng
        displayName
      }
      metadata{
        model
        description
        resolution
        lastCaptureImg
      }
      zone{
        id
        displayName
        geo{
          lat
          lng
          displayName
        }
      }
      totalRois
    }
  }
}
`