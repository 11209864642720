import { firestore, storage, analytics } from "src/boot/firebase";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage"
import { setUserProperties } from "firebase/analytics";

const pushAgentAndEventTaken = async (cid, eid, user) => {

  const activityClientsRef = doc(firestore, 'activity-on-clients', cid);
  const activityEventsRef = doc(activityClientsRef, 'activity-on-events', eid);

  setDoc(activityEventsRef, {
    email: user.email,
    identity_id: user.identityId,
    timestamp: new Date(),
    taken: true,
    username: user.displayName,  })
    .then(() => {
      // console.log('Document successfully written!');
    })
    .catch((error) => {
      console.error('Error writing document: ', error);
    });
};

const updateAgentAndEventTaken = async (cid, eid) => {

  const activityClientsRef = doc(firestore, 'activity-on-clients', cid);
  const activityEventsRef = doc(activityClientsRef, 'activity-on-events', eid);

  updateDoc(activityEventsRef,{
    taken: false,
  }).then(() => {
      // console.log("Document successfully rewritten!");
    })
    .catch((error) => {
      console.error("Error writing document update ");
    });
};


const userLogged = async (uid) => {
  const logId = Date.now();
  const activity = collection(firestore, "activity-on-users");

  await setDoc(doc(activity, uid), {
    lastActive: logId,
  });
};

const checkURLExist = async (cid) => {
  const listRef = ref(storage, `${process.env.LOGOS_BUCKET}/`);

  return listAll(listRef)
  .then((res) => {
    return res.items.find((item) => item.name.includes(cid));
    })
  .catch((error) => {
    // Uh-oh, an error occurred!
    console.log("error checkurlexist");

  });
};

const serPropertiesUserAnalytics = async (user) => {
  if (user.clients.length > 1) {
    setUserProperties(analytics, { clientName: "EntelDigital"});
  } else {
    setUserProperties(analytics, { clientName: user.clients[0].displayName});
  }
};

const readStorage = async (urlFrame) => {
  try {
  const imgRef = ref(storage, urlFrame);
  const url = getDownloadURL(imgRef)
  .then((res) => {
    // Insert url into an <img> tag to "download"
    return res
  })
  .catch((error) => {
    console.log("error");

  });
  return await url
} catch{
  console.log("ERROR READ STORAGE");
}
};


export {
  pushAgentAndEventTaken,
  updateAgentAndEventTaken,
  checkURLExist,
  serPropertiesUserAnalytics,
  userLogged,
  readStorage
};
