export const SET_ROIS_BY_SENSOR = "SET_ROIS_BY_SENSOR";
export const SET_NEW_ROI_IN_ALL_ROIS = "SET_NEW_ROI_IN_ALL_ROIS";
export const UPDATE_ROI = "UPDATE_ROI";
export const SET_DELETE_ROI = "SET_DELETE_ROI";
export const SET_ALL_SENSORS_BY_PROJECT = "SET_ALL_SENSORS_BY_PROJECT";
export const SET_CURRENT_EDITABLE_SENSOR_ID = "SET_CURRENT_EDITABLE_SENSOR_ID";
export const SET_CURRENT_EDITABLE_EVENT = "SET_CURRENT_EDITABLE_EVENT";
export const SET_SETTINGS_LOADING = "SET_SETTINGS_LOADING";
export const SET_ROI_ID_TO_EDIT = "SET_ROI_ID_TO_EDIT";
export const SET_ROI_ID_SELECTED_TO_EDIT = "SET_ROI_ID_SELECTED_TO_EDIT";
export const SET_ROI_ID_SELECTED_TO_HIDE = "SET_ROI_ID_SELECTED_TO_HIDE";
export const SET_HIDE_ALL_ROIS = "SET_HIDE_ALL_ROIS";
export const SET_DRAW_ROI = "SET_DRAW_ROI";
export const SET_RELOAD_ROI_DELETED = "SET_RELOAD_ROI_DELETED";
export const SET_NEW_ROI_TO_ADD_POINTS = "SET_NEW_ROI_TO_ADD_POINTS";
export const SET_IMAGE_PIXELS = "SET_IMAGE_PIXELS";
export const SET_NEW_EVENT_TYPE = "SET_NEW_EVENT_TYPE";
export const SET_NEW_TAGS_OPTIONS = "SET_NEW_TAGS_OPTIONS";
export const SET_EVENT_TYPES_DATA = "SET_EVENT_TYPES_DATA";
export const SET_NEW_EVENT = "SET_NEW_EVENT";
export const SET_TAGS_DATA = "SET_TAGS_DATA";
export const SET_NEW_TAG = "SET_NEW_TAG";
export const SET_TAG_DELETED = "SET_TAG_DELETED";
export const SET_EVENT_TYPE_DELETED = "SET_EVENT_TYPE_DELETED";
export const SET_FILTER_SENSORS_BY_ZONES = "SET_FILTER_SENSORS_BY_ZONES";
