import * as mutationsTypes from "./mutations-types";

const SET_EVENTS_BY_ZONE_ID = (state, events) => {
  state.allEventsByZoneId = events;
};
const SET_HISTORIC_EVENTS_BY_ZONE_ID = (state, historic_events) => {
  state.historicsEventsByZoneId = historic_events;
};
const SET_TAG_VALUE = (state, tag) => {
  state.tag = tag;
};

const SET_EVENTS_STATUS = (state, payload) => {
  const eventFound = state.allEventsByZoneId.find(
    (event) => event.id === payload.id
  );
  if (eventFound) {
    eventFound.statusName = payload.statusName;
  }
};

const SET_EVENTS_STATUS_HISTORIC = (state, payload) => {
  const eventFound = state.historicsEventsByZoneId.find(
    (event) => event.id === payload.id
  );
  if (eventFound) {
    eventFound.statusName = payload.statusName;
  }
};

const SET_NEW_COMMENT = (state, payload) => {
  const eventFound = state.allEventsByZoneId.find(
    (event) => event.id === payload.id
  );
  if (eventFound) {
    eventFound.comments = payload.comments;
  }
};
const SET_NEW_COMMENT_HISTORIC = (state, payload) => {

  const eventFound = state.historicsEventsByZoneId.find(
    (event) => event.id === payload.id
  );
  if (eventFound) {
    eventFound.comments = payload.comments;
  }
};
const SET_ROWS_PER_PAGE = (state, rows) => {
  state.rowsFrom = rows;
};
const SET_EVENTS_LOADING = (state, loadingLinearProgress) => {
  state.eventsLoading = loadingLinearProgress;
};
const SET_PAGINATION = (state, payload) => {
  state.pagination = payload;
};
const SET_DATA_EVENTS = (state, data) => {
  state.dataEvents = data;
};
const SET_DATA_EVENTS_HISTORIC = (state, dataHistoric) => {
  state.dataEventsHistoric = dataHistoric;
};
const SET_DONWLOADING_VIDEO = (state, loading) => {
  state.downloadingVideo = loading;
};
const SET_LOADING_TIMELAPSE = (state, loading) => {
  state.loadingTimelapse = loading;
};
const SET_VIDEO_SENSOR_ID = (state, id) => {
  state.videoSensorId = id;
};
const SET_URL_TIMELAPSE = (state, url) => {
  state.urlTimelapse = url;
};

const SET_SETTINGS_VIDEO_DOWNLOAD = (state, settings) => {
  state.settingsVideoDownload = settings;
};
const SET_DATE_VIDEO = (state, date) => {
  state.dateVideo = date;
};
const SET_FILTER_EVENTS_TODAY = (state, eventsFilterToday) => {
  state.filterEventsToday = eventsFilterToday;
};
const SET_FILTER_EVENTS_HISTORIC = (state, eventsFilterHistoric) => {
  state.filterEventsHistoric = eventsFilterHistoric;
};
const SET_NEW_EVENT_ON_HOLD = (state, handler) => {
  state.newEventsOnHold = handler;
};
const SET_EXPAND_TABLE = (state, expand) => {
  state.expandTable = expand;
};
const SET_COUNTER_AGGREGATIONS = (state, aggregations) => {
  state.counterAggregations = aggregations;
};

const SET_EVENTS_TAKEN = (state, eventsInfo) => {
  const eventFound = state.eventsTaken.find(
    (event) => event.id === eventsInfo.id
  );
  if (eventFound) {
    const index = state.eventsTaken.findIndex(
      (item) => item.id == eventsInfo.id
    );
    state.eventsTaken.splice(index, 1, {
      id: eventsInfo.id,
      user: eventsInfo.user,
      taken: eventsInfo.taken,
      username: eventsInfo.username,
      timestamp: eventsInfo.timestamp,
    });
  } else {
    state.eventsTaken.push({
      id: eventsInfo.id,
      user: eventsInfo.user,
      taken: eventsInfo.taken,
      username: eventsInfo.username,
      timestamp: eventsInfo.timestamp,
    });
  }
};
const SET_UPDATE_EVENTS_TAKEN = (state, eventsInfo) => {
  const index = state.eventsTaken.findIndex((item) => item.id == eventsInfo.id);
  state.eventsTaken.splice(index, 1, {
    id: eventsInfo.id,
    user: eventsInfo.user,
    taken: eventsInfo.taken,
    username: eventsInfo.username,
    timestamp: eventsInfo.timestamp,
  });
};
const SET_REMOVE_EVENTS_TAKEN = (state, id) => {
  const index = state.eventsTaken.findIndex((item) => item.id == id);
  if (index >= 0) {
    state.eventsTaken.splice(index, 1);
  }
};
const SET_ALERT_SOUND_EVENTS = (state, sound) => {
  state.alertSoundEvents = sound;
};
const SET_EVENT_ID_SELECTED = (state, eventId) => {
  state.eventIdSelected = eventId;
};
const SET_PAGINATION_CHANGE = (state, change) => {
  state.paginationChange = change;
};
const SET_EVENT_TYPES_BY_PROJECT = (state, events) => {
  state.eventTypesByProject = events;
};

export default {
  [mutationsTypes.SET_EVENTS_BY_ZONE_ID]: SET_EVENTS_BY_ZONE_ID,
  [mutationsTypes.SET_HISTORIC_EVENTS_BY_ZONE_ID]:
    SET_HISTORIC_EVENTS_BY_ZONE_ID,
  [mutationsTypes.SET_TAG_VALUE]: SET_TAG_VALUE,
  [mutationsTypes.SET_EVENTS_STATUS]: SET_EVENTS_STATUS,
  [mutationsTypes.SET_EVENTS_STATUS_HISTORIC]: SET_EVENTS_STATUS_HISTORIC,
  [mutationsTypes.SET_NEW_COMMENT]: SET_NEW_COMMENT,
  [mutationsTypes.SET_NEW_COMMENT_HISTORIC]: SET_NEW_COMMENT_HISTORIC,
  [mutationsTypes.SET_ROWS_PER_PAGE]: SET_ROWS_PER_PAGE,
  [mutationsTypes.SET_EVENTS_LOADING]: SET_EVENTS_LOADING,
  [mutationsTypes.SET_PAGINATION]: SET_PAGINATION,
  [mutationsTypes.SET_DATA_EVENTS]: SET_DATA_EVENTS,
  [mutationsTypes.SET_DATA_EVENTS_HISTORIC]: SET_DATA_EVENTS_HISTORIC,
  [mutationsTypes.SET_DONWLOADING_VIDEO]: SET_DONWLOADING_VIDEO,
  [mutationsTypes.SET_LOADING_TIMELAPSE]: SET_LOADING_TIMELAPSE,
  [mutationsTypes.SET_VIDEO_SENSOR_ID]: SET_VIDEO_SENSOR_ID,
  [mutationsTypes.SET_URL_TIMELAPSE]: SET_URL_TIMELAPSE,
  [mutationsTypes.SET_SETTINGS_VIDEO_DOWNLOAD]: SET_SETTINGS_VIDEO_DOWNLOAD,
  [mutationsTypes.SET_DATE_VIDEO]: SET_DATE_VIDEO,
  [mutationsTypes.SET_FILTER_EVENTS_TODAY]: SET_FILTER_EVENTS_TODAY,
  [mutationsTypes.SET_FILTER_EVENTS_HISTORIC]: SET_FILTER_EVENTS_HISTORIC,
  [mutationsTypes.SET_NEW_EVENT_ON_HOLD]: SET_NEW_EVENT_ON_HOLD,
  [mutationsTypes.SET_EXPAND_TABLE]: SET_EXPAND_TABLE,
  [mutationsTypes.SET_COUNTER_AGGREGATIONS]: SET_COUNTER_AGGREGATIONS,
  [mutationsTypes.SET_EVENTS_TAKEN]: SET_EVENTS_TAKEN,
  [mutationsTypes.SET_UPDATE_EVENTS_TAKEN]: SET_UPDATE_EVENTS_TAKEN,
  [mutationsTypes.SET_REMOVE_EVENTS_TAKEN]: SET_REMOVE_EVENTS_TAKEN,
  [mutationsTypes.SET_ALERT_SOUND_EVENTS]: SET_ALERT_SOUND_EVENTS,
  [mutationsTypes.SET_EVENT_ID_SELECTED]: SET_EVENT_ID_SELECTED,
  [mutationsTypes.SET_PAGINATION_CHANGE]: SET_PAGINATION_CHANGE,
  [mutationsTypes.SET_EVENT_TYPES_BY_PROJECT]: SET_EVENT_TYPES_BY_PROJECT
};
