
export default `
mutation UpdateUser($user: UserInput!) {
    UpdateUser(user: $user){
      id
      identityId
      displayName
      firstName
      lastName
      email
      phone
      root
      identityProvider
    }
  }
`;