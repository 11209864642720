export const SET_EVENTS_BY_ZONE_ID = "SET_EVENTS_BY_ZONE_ID";
export const SET_HISTORIC_EVENTS_BY_ZONE_ID = "SET_HISTORIC_EVENTS_BY_ZONE_ID";
export const SET_TAG_VALUE = "SET_TAG_VALUE";
export const SET_EVENTS_STATUS = "SET_EVENTS_STATUS";
export const SET_EVENTS_STATUS_HISTORIC = "SET_EVENTS_STATUS_HISTORIC";
export const SET_NEW_COMMENT = "SET_NEW_COMMENT";
export const SET_NEW_COMMENT_HISTORIC = "SET_NEW_COMMENT_HISTORIC";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";
export const SET_EVENTS_LOADING = "SET_EVENTS_LOADING";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_DATA_EVENTS = "SET_DATA_EVENTS";
export const SET_DATA_EVENTS_HISTORIC = "SET_DATA_EVENTS_HISTORIC";
export const SET_DONWLOADING_VIDEO = "SET_DONWLOADING_VIDEO";
export const SET_LOADING_TIMELAPSE = "SET_LOADING_TIMELAPSE";
export const SET_VIDEO_SENSOR_ID = "SET_VIDEO_SENSOR_ID";
export const SET_URL_TIMELAPSE = "SET_URL_TIMELAPSE";
export const SET_SETTINGS_VIDEO_DOWNLOAD = "SET_SETTINGS_VIDEO_DOWNLOAD";
export const SET_DATE_VIDEO = "SET_DATE_VIDEO";
export const SET_FILTER_EVENTS_TODAY = "SET_FILTER_EVENTS_TODAY";
export const SET_FILTER_EVENTS_HISTORIC = "SET_FILTER_EVENTS_HISTORIC";
export const SET_NEW_EVENT_ON_HOLD = "SET_NEW_EVENT_ON_HOLD";
export const SET_EXPAND_TABLE = "SET_EXPAND_TABLE";
export const SET_COUNTER_AGGREGATIONS = "SET_COUNTER_AGGREGATIONS";
export const SET_EVENTS_TAKEN = "SET_EVENTS_TAKEN";
export const SET_UPDATE_EVENTS_TAKEN = "SET_UPDATE_EVENTS_TAKEN";
export const SET_REMOVE_EVENTS_TAKEN = "SET_REMOVE_EVENTS_TAKEN";
export const SET_ALERT_SOUND_EVENTS = "SET_ALERT_SOUND_EVENTS";
export const SET_EVENT_ID_SELECTED = "SET_EVENT_ID_SELECTED";
export const SET_PAGINATION_CHANGE = "SET_PAGINATION_CHANGE";
export const SET_EVENT_TYPES_BY_PROJECT = "SET_EVENT_TYPES_BY_PROJECT";

