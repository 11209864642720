export default `
query AllZones($clientId: String!, $projectId: String!, $filter:[DataFilterInput], $from:Int, $size:Int, $sort:DataQuerySortInput) {
  AllZones(clientId: $clientId, projectId: $projectId, filter:$filter,  from:$from, size:$size, sort:$sort) {
    total
    zones{
      totalSensors
      id
      displayName
      geo {
        lat
        lng
        displayName
      }
    }
  }
}
`;
