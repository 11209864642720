export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const FETCH_CLIENTS_BY_USER = "FETCH_CLIENTS_BY_USER";
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const RESTORE_PASSWORD = "RESTORE_PASSWORD";
export const CREATE_NEW_PASSWORD = "CREATE_NEW_PASSWORD";
export const CHECK_SESSION = "CHECK_SESSION";
export const UNLISTEN_SESSION = "UNLISTEN_SESSION";
export const GET_LOGO_CLIENT = "GET_LOGO_CLIENT";
export const FETCH_PROFILES_BY_CLIENT_ID = "FETCH_PROFILES_BY_CLIENT_ID";
export const UPDATE_USER = "UPDATE_USER";
export const LAST_INTERACTION_USER = "LAST_INTERACTION_USER";
export const DELETE_USER = "DELETE_USER";
