import { date } from "quasar";

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const renamePointsToLatLngs = (points, pixels) => {
  const resolutionWidth = 1920;
  const resolutionHeight = 1080;
  const calculateWidth = ((pixels.width * 100) / resolutionWidth).toFixed(2);
  const calculateHeight = ((pixels.height * 100) / resolutionHeight).toFixed(2);
  return points.reduce((arr, point, i) => {
    arr.push({ lng: (point.x * calculateWidth) / 100, lat: (point.y * calculateHeight) / 100 });
    return arr;
  }, []);
};

export const renameLatLngsToPoints = (latLngs, pixels) => {
  const resolutionWidth = 1920;
  const resolutionHeight = 1080;
  const calculateWidth = ((pixels.width * 100) / resolutionWidth).toFixed(2);
  const calculateHeight = ((pixels.height * 100) / resolutionHeight).toFixed(2);
  return latLngs.reduce((arr, latLng) => {
    arr.push({ x: (latLng.lng * 100) / calculateWidth, y: (latLng.lat * 100) / calculateHeight });
    return arr;
  }, []);
};

export const getDifferent = (array1, array2) => {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.eventId === object2.id;
    });
  });
};

export const allSensorZone = (arr) => {
  const list = arr.map((el) => {
    return el.sensors.map((sensor) => {
      return Object.assign(sensor, { zoneId: el.id, zoneName: el.displayName});
    });
  });
  return list.flat();
};

export const twoDigitsNumber = (num) => {
  return num < 10 ? `0${num}` : num;
};

export const sum = (arr) => {
  return arr.reduce((accumulator, object) => {
    return accumulator + object;
  }, 0);
};

export const getUniques = (arr, parameters) => {
  let uniqueObjArray = [];
  if (typeof parameters.filter === "string") {
    uniqueObjArray = [
      ...new Map(arr.map((item) => [item[parameters.filter], item])).values(),
    ];
  } else {
    uniqueObjArray = [
      ...new Map(
        arr.map((item) => item[parameters.filter[0]][parameters.filter[1]])
      ).values(),
    ];
  }
  return uniqueObjArray.map((event) => {
    if (typeof parameters.value === "string") {
      return {
            label: event[parameters.label],
            value: event[parameters.value],
            id: event[parameters.value],
            geo: event[parameters.geo],
            zoneId: event.zoneId,
            zoneName: event.zoneName,
            displayName: event[parameters.label],
            metadata: event[parameters.metadata]
          }
    } else {
      return {
        label: event[parameters.label[0]][parameters.label[1]],
        value: event[parameters.value[0]][parameters.value[1]],
      };
    }
  });
};

export const cleanFormatWord = (str) => {
  const cleanWord = str.replace(/_|#|-|@|<>/g, " ");
  return cleanWord.charAt(0).toUpperCase() + cleanWord.slice(1).toLowerCase();
};

export const toTitleCase = (str) => {
  return str.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const capitalizeFirstLetter = (displayName) => {
  return displayName.charAt(0).toUpperCase() + displayName.slice(1);
};


export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const sortItems = (array, sortBy) => {
 return array.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
};

export const sortAlphaNumeric = (array, sortBy) => {
  return array.sort((a, b) => {
    return a[sortBy].localeCompare(b[sortBy], undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  });
};

export const datesFormat = (mydate) => {
  const dateSeparete = mydate.split("|")
  const dateJoined = dateSeparete[0].concat(dateSeparete[1])
  return new Date(dateJoined)
}

export const dateUTCToLocal = (mydate) => {
  const getTimeZoneOffsetChile = new Date(mydate).getTimezoneOffset();
  const chileTimeZone = date.subtractFromDate(mydate, {
    minutes: getTimeZoneOffsetChile,
  });
  return chileTimeZone
}

export const formatDateAndTime = (eventDate) => {
  let timestamp;
  if(eventDate) {
    const utcDate = new Date(eventDate);
    const getTimeZoneOffsetChile = utcDate.getTimezoneOffset();
    const chileTimeZone = date.subtractFromDate(eventDate, {
      minutes: getTimeZoneOffsetChile,
    });
  
    timestamp = {
      date: date.formatDate(chileTimeZone, "DD/MM/YYYY"),
      time: date.formatDate(chileTimeZone, "HH:mm:ss"),
    };
  } else timestamp = {
    date: "-",
    time: "-",
  }
  return timestamp;
}
