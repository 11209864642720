export default {
  allRoisBySensor: [],
  allSensorsByProject: [],
  currentSensorEditableId: "",
  currentEventEditable: {},
  settingsLoading: false,
  roiIdToEdit: "",
  roiIdSelectedToEdit: {},
  roiIdSelectedToHide: {
    hide: false,
    id: ""
  },
  hideAllRois: false,
  drawRoi: false,
  reloadRoiDeleted: false,
  imagePixels: {
    width: 0,
    height: 0
  },
  newEventType: {},
  newTagsOptions: [],
  eventTypesData: [],
  tagsData: [],
  newRoiToAddPoints: {},
  filterSensorsByZones: []
};
