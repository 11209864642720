import { getCurrentUser } from "src/services/login";
import { uuid } from "src/utils/functions";
import axios from "axios";

export const Post = async body => {
  const uuidRequest = uuid()
    try {
      const { token } = await getCurrentUser();
      const url = `${process.env.API_GRAPHQL}`;
      const { data, status } = await axios.post(url, JSON.stringify(body), {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-REQ-ID": `${uuidRequest}`
        },
      });
      if (Array.isArray(data.errors) && data.errors.length > 0) {
        const [err] = data.errors;
        const { message } = err;
        throw new Error(`invalid error message=${message}`);
      }

      if (data.data == null) {
        throw new Error("invalid null data response");
      }

      if (status !== 200) {
        throw new Error(`invalid request status=${status}`);
      }

      return data;
    } catch (err) {
      console.log("HERE IN ERROR POST");
      throw err;
    }
  };
