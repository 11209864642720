import * as getterTypes from "./getters-types";

const GET_SENSORS_LIST = (state) => {
  const listSensors = []
  state.allSensorsByProject.map(sensor => {
    listSensors.push({
      label: sensor.displayName,
      value: sensor.id,
      id:sensor.id,
      zoneId: sensor.zone.id,
    })
  })
  return listSensors
};

export default {
  [getterTypes.GET_SENSORS_LIST]: GET_SENSORS_LIST,
};
