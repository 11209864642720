const routes = [
  {
    path: "/",
    component: () => import("layouts/Login.vue"),
    meta: { authorize: false },
    children: [
      {
        path: "",
        name: "login",
        component: () => import("src/pages/Login/Index.vue"),
      },
      {
        path: "reset-password",
        name: "reset-password",
        component: () => import("src/pages/Login/ResetPassword/Index.vue"),
      },
      {
        path: "new-password",
        name: "new-password",
        component: () => import("src/pages/Login/CreatePassword/Index.vue"),
      },
    ],
    metadata: {
      title: "Login",
      navigable: false,
    },
  },
  {
    path: "/dashboard",
    component: () => import("layouts/MainLayout.vue"),
    meta: { authorize: true },
    name: "dashboard",
    props: true,
    children: [
      {
        path: "admin",
        name: "admin",
        meta: { code: "users"},
        component: () => import("src/pages/Index.vue"),
      },
      {
        path: "reports/clientId=:clientId&projectId=:projectId&tab=:tab",
        name: "reports",
        meta: { code: "reports"},
        props: true,
        component: () => import("src/pages/Core/Reports/Index.vue"),
      },
      {
        path: "zones/clientId=:clientId&projectId=:projectId",
        name: "zones",
        meta: { code: "events"},
        props: true,
        component: () => import("src/pages/Core/Zones/Index.vue"),
      },
      {
        path: "zones/events-management/clientId=:clientId&projectId=:projectId&zoneId=:zoneId&tab=:tab",
        name: "events-management",
        meta: { code: "events"},
        props: true,
        component: () => import("src/pages/Core/Events/Index.vue"),
      },
      {
        path: "settings/clientId=:clientId&projectId=:projectId&tab=:tab",
        name: "settings",
        meta: { code: "settings"},
        props: true,
        component: () => import("src/pages/Core/Settings/Index.vue"),
      },
      {
        path: "settings/edit-event/clientId=:clientId&projectId=:projectId",
        name: "edit-event",
        meta: { code: "settings"},
        props: true,
        component: () => import("src/pages/Core/Settings/Sections/EditEvent/Index.vue"),
      },
      {
        path: "users/clientId=:clientId&projectId=:projectId",
        name: "users",
        meta: { code: "users"},
        props: true,
        component: () => import("src/pages/Core/Client/Index.vue"),
      },
      {
        path: "users/add-user/clientId=:clientId&projectId=:projectId",
        name: "add-user",
        meta: { code: "users"},
        props: true,
        component: () => import("src/pages/Core/Client/Sections/AddUser/Index.vue"),
      },
      {
        path: "notifications/clientId=:clientId&projectId=:projectId",
        name: "notifications",
        meta: { code: "notifications"},
        props: true,
        component: () => import("src/pages/Core/Notifications/Index.vue"),
      },
      {
        path: "notifications/add-notification/clientId=:clientId&projectId=:projectId",
        name: "add-notification",
        meta: { code: "notifications"},
        props: true,
        component: () => import("src/pages/Core/Notifications/Sections/AddNotification/Index.vue"),
      },
    ],
    metadata: {
      title: "Dashboard",
      navigable: true,
      separator: true,
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("src/pages/Error404.vue"),
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("src/pages/Error404.vue"),
  },
];

export default routes;
