export default `
query GetClients ($clientId: String, $userId: String!){
    GetClients (clientId: $clientId, userId: $userId){
        id
        displayName
        rut
        projects {
          id
          displayName
          geo {
            displayName
            lat
            lng
          }
        }
        users {
          id
          email
          firstName
          lastName
          displayName
          lastSignInTimestamp
        }
    }
}
`