export default `
query GetProjectsWithEventsSetToShow($identityId:String!,$projectId:String!){
  GetProjectsWithEventsSetToShow(identityId:$identityId, projectId:$projectId){
    projectId
    projectDisplayName
    eventTypes{
          id
          displayName
          type
          timeAgregacion
          timeIntervalo
          priorityId
          incomingEventTypeId
          show
    }
  }
}
`
