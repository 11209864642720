import * as actionsTypes from "./actions-types";
import * as mutationsTypes from "./mutations-types";
import {
  getRois,
  createNewRoi,
  updateRoi,
  deleteRoi,
  getAllSensorsByProject
} from "src/services/cv-api/module/settings/settings.js";
import {
  sortItems,
  sortAlphaNumeric,
  renamePointsToLatLngs
} from "src/utils/functions";

const FETCH_ROIS_BY_SENSOR = async (
  { commit, state },
  { sensorId }
) => {
  try {
    commit(mutationsTypes.SET_SETTINGS_LOADING, true);

    const rois = await getRois(sensorId);
    const dataToCommit = [];
    rois.GetRois.map((roi) => {
      dataToCommit.push({
        id: roi.id,
        displayName: roi.displayName,
        color: roi.color,
        points: renamePointsToLatLngs(roi.points, state.imagePixels),
        eventType: { displayName: roi.eventTypes ? roi.eventTypes[0] : "", id: roi.eventTypeId}
      });
    });
    sortItems(dataToCommit, "id");

    commit(mutationsTypes.SET_ROIS_BY_SENSOR, dataToCommit);
    commit(mutationsTypes.SET_SETTINGS_LOADING, false);
  } catch (error) {
    console.log("ERROR ROIS");
    commit(mutationsTypes.SET_SETTINGS_LOADING, false);
  }
};

const ADD_NEW_ROI = async ({ commit, state }, { newRoi }) => {
  try {
    const sensorId = newRoi.sensorId;
    const newRoiAdded = await createNewRoi(newRoi, sensorId);
    const newRoiToCommit = {
      id: newRoiAdded.CreateRoi.id,
      displayName: newRoiAdded.CreateRoi.displayName,
      color: newRoiAdded.CreateRoi.color,
      points: renamePointsToLatLngs(newRoiAdded.CreateRoi.points, state.imagePixels),
      eventType: { displayName: newRoiAdded.CreateRoi.eventTypes[0], id: newRoiAdded.CreateRoi.eventTypeId}
    };

    commit(mutationsTypes.SET_NEW_ROI_IN_ALL_ROIS, newRoiToCommit);
    commit(mutationsTypes.SET_ROI_ID_TO_EDIT, newRoiToCommit.id)
  } catch (error) {
    console.log("ERROR NEW ROI");
  }
};

const UPDATE_ROI = async (
  { commit, state },
  { roiUpdated }
) => {
  const sensorId = state.currentSensorEditableId;
  try {
    const roiEdited = await updateRoi(roiUpdated, sensorId);
    const updatedRoiToCommit = {
      id: roiEdited.UpdateRoi.id,
      displayName: roiEdited.UpdateRoi.displayName,
      color: roiEdited.UpdateRoi.color,
      points: renamePointsToLatLngs(roiEdited.UpdateRoi.points, state.imagePixels),
      eventType: { displayName: roiEdited.UpdateRoi.eventTypes[0], id: roiEdited.UpdateRoi.eventTypeId}
    };

    commit(mutationsTypes.UPDATE_ROI, updatedRoiToCommit)
  } catch (error) {
    console.log("ERROR UPDATE ROI");
  } finally {
  }
};

const DELETE_ROI = async (
  { commit, state },
  { roiId }
) => {
  try {
    const isRoiDeleted = await deleteRoi(roiId);
    commit(mutationsTypes.SET_RELOAD_ROI_DELETED, isRoiDeleted.DeleteRoi)
  } catch (error) {
    console.log("ERROR DELETE ROI");
  } finally {
  }
};

const FETCH_SENSORS_BY_PROJECT = async (
  { commit, state },
  { clientId, projectId }
) => {
  try {
    commit(mutationsTypes.SET_SETTINGS_LOADING, true);
    const variables = { clientId, projectId };
    const filter = { 
      object: "zones", 
      values: { field: "id" } 
    };

    variables.sort = { field: "displayName", sort: "ASC" };
    if(state.filterSensorsByZones.length > 0) {
      filter.values.operator = { in: state.filterSensorsByZones };
      variables.filter = filter;
    }

    const sensors = await getAllSensorsByProject(variables);

    sortAlphaNumeric(sensors.AllSensorsByProject.sensors, "displayName");
    
    commit(mutationsTypes.SET_ALL_SENSORS_BY_PROJECT, sensors.AllSensorsByProject.sensors);
    commit(mutationsTypes.SET_SETTINGS_LOADING, false);
  } catch (error) {
    console.log("ERROR SENSORS");
    commit(mutationsTypes.SET_SETTINGS_LOADING, false);
  }
};

export default {
  [actionsTypes.FETCH_ROIS_BY_SENSOR]: FETCH_ROIS_BY_SENSOR,
  [actionsTypes.ADD_NEW_ROI]: ADD_NEW_ROI,
  [actionsTypes.UPDATE_ROI]: UPDATE_ROI,
  [actionsTypes.DELETE_ROI]: DELETE_ROI,
  [actionsTypes.FETCH_SENSORS_BY_PROJECT]: FETCH_SENSORS_BY_PROJECT
};
