import { queries, mutations } from "src/services/cv-api/graphql";
import { Post } from "src/services/cv-api/utils";


export const listAllProcessedEvents = async (clientId, projectId, sort, from, pit, size, aggregations, filter) => {
  const body = {
    query: queries.listAllProcessedEvents,
    variables: {
      clientId,
      projectId,
      sort,
      from,
      pit,
      size,
      aggregations,
      filter
    }
  };
  const { data } = await Post(body);
  return data;
};

export const CreateProcessedComment = async (
  clientId,
  projectId,
  where,
  set
) => {
  const body = {
    query: mutations.CreateProcessedComment,
    variables: {
      clientId,
      projectId,
      where,
      set
    }
  };
  const { data } = await Post(body);
  return data;
};

export const updateProcessedEventStatus = async (
  clientId,
  projectId,
  where,
  set
) => {
  const body = {
    query: mutations.updateEventStatus,
    variables: {
      clientId,
      projectId,
      where,
      set
    }
  };

  const { data } = await Post(body);
  return data;
};

export const getVideo = async (clientId, projectId, settings) => {
  const body = {
    query: queries.getVideo,
    variables: {
      clientId,
      projectId,
      settings
    }
  };

  const { data } = await Post(body);
  return data;
};

export const getEventsTypesByProjects = async (identityId, projectId) => {
  const body = {
    query: queries.getEventsTypesByProjects,
    variables: {
      identityId,
      projectId
    }
  };

  const { data } = await Post(body);
  return data;
};
