import Vue from "vue";
import Vuex from "vuex";
import zones from "./zones";
import events from "./events";
import user from "./user";
import settings from "./settings";
import PersistedState from "./persistedState";

Vue.use(Vuex);

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */
const Store = new Vuex.Store({
  modules: {
    zones,
    events,
    user,
    settings
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEV,
  plugins: [PersistedState]

});

export default Store;
