export default `
mutation CreateUser($clientId: String!, $projectId: String!, $user: UserInput!) {
  CreateUser(clientId: $clientId, projectId: $projectId, user: $user){
    id
    profileId
    displayName
    firstName
    lastName
    email
    root
    identityProvider
    password
  }
}
`