export default `
mutation CreateProcessedEventComment($clientId: String!, $projectId: String!, $where: UpdateIdOperatorInput!, $set: UpdateProcessedEventInput!) {
  CreateProcessedEventComment(clientId: $clientId, projectId: $projectId, where: $where, set: $set) {
    id
    comments {
        id
        message
        timestamp
        userId
        userName
    }
  }
}
`;


