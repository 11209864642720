export default `
query AllProcessedEvents($clientId: String!, $projectId: String!, $sort: DateTimeSortOperatorInputInput, $filter:ProcessedEventsFilterInput, $aggregations:ProcessedEventsAggregationInput, $from:Int, $size: Int, $pit:String){
  AllProcessedEvents( clientId:$clientId, projectId:$projectId, sort:$sort, filter:$filter, aggregations:$aggregations, from:$from, size:$size, pit:$pit ) {
 	 processedEvents{
      id
      type
      deviceId
      deviceType
      timestamp
      schemaVersion
      frames{
        id
        url
        timestamp
        resolution 
        detectionInferences{
          id
          type
          data{
            object
            confidence
            boundingBox{
              xmin
              ymin
              xmax
              ymax
            }
            rois{
              id
              displayName
              eventTypes
              points{
                x
                y
              }
              color
              sensorId
            }
          }
        }
      }
      metadata{
        sensorId
        sensorName
        sensorType
        sensorGeo{
          lat
          lng
        }
        zoneId
        zoneName
        clientId
        clientName
      }
      priorityId
      priorityName
      statusId
      statusName
      comments{
        id
        message
        timestamp
        userId
        userName
      }
    }
    pit
    total
    queryAggregationOutputs{
      bucket
      aggregationResponse{
        key
        count
    }
  }
}
}
`;
