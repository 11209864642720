import * as mutationsTypes from "./mutations-types";
import { sortItems } from "src/utils/functions";

const SET_ROIS_BY_SENSOR = (state, allRois) => {
  state.allRoisBySensor = allRois;
};

const SET_NEW_ROI_IN_ALL_ROIS = (state, payload) => {
  state.allRoisBySensor.push(payload);
};

const UPDATE_ROI = (state, payload) => {
  const roiFound = state.allRoisBySensor.find((roi) => roi.id === payload.id);
  roiFound.displayName = payload.displayName;
  roiFound.color = payload.color;
  roiFound.points = payload.points;
  roiFound.eventType = payload.eventType;
};

const SET_DELETE_ROI = (state, payload) => {
  state.allRoisBySensor.splice(payload, 1);
};

const SET_RELOAD_ROI_DELETED = (state, reload) => {
  state.reloadRoiDeleted = reload;
};

const SET_ALL_SENSORS_BY_PROJECT = (state, sensors) => {
  state.allSensorsByProject = sensors;
};

const SET_CURRENT_EDITABLE_SENSOR_ID = (state, sensor) => {
  state.currentSensorEditableId = sensor;
};

const SET_CURRENT_EDITABLE_EVENT = (state, event) => {
  state.currentEventEditable = event;
};

const SET_SETTINGS_LOADING = (state, loadingLinearProgress) => {
  state.settingsLoading = loadingLinearProgress;
};

const SET_ROI_ID_TO_EDIT = (state, id) => {
  state.roiIdToEdit = id;
};

const SET_ROI_ID_SELECTED_TO_EDIT = (state, id) => {
  state.roiIdSelectedToEdit = id;
};

const SET_ROI_ID_SELECTED_TO_HIDE = (state, id) => {
  state.roiIdSelectedToHide = id;
};

const SET_HIDE_ALL_ROIS = (state, id) => {
  state.hideAllRois = id;
};

const SET_DRAW_ROI = (state, draw) => {
  state.drawRoi = draw;
};

const SET_NEW_ROI_TO_ADD_POINTS = (state, data) => {
  state.newRoiToAddPoints.points = data.points;
};

const SET_IMAGE_PIXELS = (state, pxSize) => {
  state.imagePixels = pxSize;
};

const SET_NEW_EVENT_TYPE = (state, eventType) => {
  state.newEventType = eventType;
};

const SET_NEW_TAGS_OPTIONS = (state, tagsOptions) => {
  if (tagsOptions.length === 0) {
    state.newTagsOptions = tagsOptions;
  }
  tagsOptions.map((tag) => state.newTagsOptions.push(tag));
};

const SET_EVENT_TYPES_DATA = (state, data) => {
  state.eventTypesData.find((event) => {
    if (event.id === data.id) {
      event.parameters = data.eventType.parameters;
      event.color = data.eventType.color;
      event.displayName !== data.eventType.displayName
        ? (event.displayName = data.eventType.displayName)
        : event.displayName;
      event.tags = data.tags;
      event.clientId = data.clientId;
    }
  });
  if (data.length > 0) {
    state.eventTypesData = data;
  }

  sortItems(state.eventTypesData, "displayName");
};
const SET_NEW_EVENT = (state, data) => {
  state.eventTypesData.push({
    id: data.id,
    parameters: data.eventType.parameters,
    color: data.eventType.color,
    displayName: data.eventType.displayName,
    tags: data.tags,
    clientId: data.clientId,
  });

  sortItems(state.eventTypesData, "displayName");
};

const SET_EVENT_TYPE_DELETED = (state, idEvent) => {
  const indexEvent = state.eventTypesData.indexOf(idEvent);
  if (indexEvent > -1) {
    state.eventTypesData.splice(indexEvent, 1);
  } else {
    state.eventTypesData = [];
  }
  sortItems(state.eventTypesData, "displayName");
};

const SET_TAGS_DATA = (state, tags) => {
  state.tagsData = tags;
};

const SET_NEW_TAG = (state, tag) => {
  state.tagsData.push(tag);
};

const SET_TAG_DELETED = (state, indexTag) => {
  if (indexTag > -1) {
    state.tagsData.splice(indexTag, 1);
  }
};
const SET_FILTER_SENSORS_BY_ZONES = (state, zones) => {
  state.filterSensorsByZones = zones
};

export default {
  [mutationsTypes.SET_ROIS_BY_SENSOR]: SET_ROIS_BY_SENSOR,
  [mutationsTypes.SET_NEW_ROI_IN_ALL_ROIS]: SET_NEW_ROI_IN_ALL_ROIS,
  [mutationsTypes.UPDATE_ROI]: UPDATE_ROI,
  [mutationsTypes.SET_DELETE_ROI]: SET_DELETE_ROI,
  [mutationsTypes.SET_ALL_SENSORS_BY_PROJECT]: SET_ALL_SENSORS_BY_PROJECT,
  [mutationsTypes.SET_CURRENT_EDITABLE_SENSOR_ID]:
    SET_CURRENT_EDITABLE_SENSOR_ID,
  [mutationsTypes.SET_CURRENT_EDITABLE_EVENT]: SET_CURRENT_EDITABLE_EVENT,
  [mutationsTypes.SET_SETTINGS_LOADING]: SET_SETTINGS_LOADING,
  [mutationsTypes.SET_ROI_ID_TO_EDIT]: SET_ROI_ID_TO_EDIT,
  [mutationsTypes.SET_ROI_ID_SELECTED_TO_EDIT]: SET_ROI_ID_SELECTED_TO_EDIT,
  [mutationsTypes.SET_ROI_ID_SELECTED_TO_HIDE]: SET_ROI_ID_SELECTED_TO_HIDE,
  [mutationsTypes.SET_HIDE_ALL_ROIS]: SET_HIDE_ALL_ROIS,
  [mutationsTypes.SET_DRAW_ROI]: SET_DRAW_ROI,
  [mutationsTypes.SET_RELOAD_ROI_DELETED]: SET_RELOAD_ROI_DELETED,
  [mutationsTypes.SET_NEW_ROI_TO_ADD_POINTS]: SET_NEW_ROI_TO_ADD_POINTS,
  [mutationsTypes.SET_IMAGE_PIXELS]: SET_IMAGE_PIXELS,
  [mutationsTypes.SET_NEW_EVENT_TYPE]: SET_NEW_EVENT_TYPE,
  [mutationsTypes.SET_NEW_TAGS_OPTIONS]: SET_NEW_TAGS_OPTIONS,
  [mutationsTypes.SET_EVENT_TYPES_DATA]: SET_EVENT_TYPES_DATA,
  [mutationsTypes.SET_NEW_EVENT]: SET_NEW_EVENT,
  [mutationsTypes.SET_TAGS_DATA]: SET_TAGS_DATA,
  [mutationsTypes.SET_NEW_TAG]: SET_NEW_TAG,
  [mutationsTypes.SET_TAG_DELETED]: SET_TAG_DELETED,
  [mutationsTypes.SET_EVENT_TYPE_DELETED]: SET_EVENT_TYPE_DELETED,
  [mutationsTypes.SET_FILTER_SENSORS_BY_ZONES]: SET_FILTER_SENSORS_BY_ZONES
};
