export default `
mutation CreateClient($client: ClientInInput!) {
  CreateClient(input: $client){
    id
    displayName
    rut
  }
}
  `;

