import * as getterTypes from "./getters-types";
import { sortItems } from "src/utils/functions";

const GET_OPTIONS_CLIENTS = (state) => {
  const options = [];
  state.user.clients.map((el) => {
    options.push({
      value: el.id,
      label: el.displayName,
    });
  });
  return options;
};

const GET_USERS_BY_CLIENTS = (state) => {
  const allUsers =  state.clientsByUser.reduce((arr, client, i) => {
    const { projects, users, id, displayName } = client;
    users.map((user) => {
      arr.push({
        email: user.email,
        id: user.id,
        name: user.displayName,
        firstName: user.firstName,
        lastName: user.lastName,
        client: displayName,
        clientId: id,
        projects: projects,
        permits: [1,2,3,4,5],
        lastSignIn: user.lastSignInTimestamp
      });
    });
    return arr;
  }, []);
  
  sortItems(allUsers, "name");

  return allUsers
};


export default {
  [getterTypes.GET_OPTIONS_CLIENTS]: GET_OPTIONS_CLIENTS,
  [getterTypes.GET_USERS_BY_CLIENTS]: GET_USERS_BY_CLIENTS
};
