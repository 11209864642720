import * as mutationTypes from "./mutations-types";
import { sortItems } from "src/utils/functions";

const SET_USER = (state, user) => {
  state.user = user;
};

const SET_UPDATE_USER_BY_NEW_CLIENT = (state, newClient) => {
  newClient.projects = [];
  state.user.clients.push(newClient);
  sortItems(state.user.clients, "displayName");
};

const SET_UPDATE_USER_BY_NEW_PROJECT = (state, newProject) => {
  state.user.clients.find(client => {
    if(client.id === newProject.clientId) {
      newProject.project.clientId = newProject.clientId;
      client.projects.push(newProject.project);
    }
    sortItems(client.projects, "displayName");
  })
};

const SET_PROJECT_SELECTED = (state, project) => {
  state.projectSelected = project;
  if(state.projectSelected.dashboards) {
    state.projectSelected.dashboards.sort((a, b) => a.order - b.order);
  }
};

const SET_CLIENTS_BY_USER = (state, clients) => {
  state.clientsByUser = clients;
};

const SET_NEW_CLIENT = (state, payload) => {
  state.newClient = payload;
};

const SET_NEW_USER = (state, payload) => {
  state.newUser = payload;
};

const SET_CLIENT_SELECTED = (state, client) => {
  state.clientSelected = client;
};

const SET_USER_LOADING = (state, loadingLinearProgress) => {
  state.userLoading = loadingLinearProgress;
};

const SET_ACTIVE_ITEM_MENU = (state, item) => {
  state.activeItemMenu = item;
};

const SET_ERROR = (state, error) => {
  state.error = error;
};

const SET_URL_LOGO = (state, url) => {
  state.urlLogo = url;
};

const SET_PROFILES_BY_CLIENT_ID = (state, profiles) => {
  state.profilesByClientId = profiles;
};

const SET_PASSWORD_GENERATED = (state, password) => {
  state.passwordGenerated = password;
};

const SET_USER_DATA_TO_EDIT = (state, user) => {
  state.userDataToEdit = user;
};

const SET_USER_UPDATED = (state, user) => {
  state.userUpdated = user;
};

const SET_USER_DELETED = (state, deleted) => {
  state.userDeleted = deleted;
};



export default {
  [mutationTypes.SET_USER]: SET_USER,
  [mutationTypes.SET_UPDATE_USER_BY_NEW_CLIENT]: SET_UPDATE_USER_BY_NEW_CLIENT,
  [mutationTypes.SET_UPDATE_USER_BY_NEW_PROJECT]: SET_UPDATE_USER_BY_NEW_PROJECT,
  [mutationTypes.SET_PROJECT_SELECTED]: SET_PROJECT_SELECTED,
  [mutationTypes.SET_CLIENTS_BY_USER]: SET_CLIENTS_BY_USER,
  [mutationTypes.SET_NEW_CLIENT]: SET_NEW_CLIENT,
  [mutationTypes.SET_NEW_USER]: SET_NEW_USER,
  [mutationTypes.SET_CLIENT_SELECTED]: SET_CLIENT_SELECTED,
  [mutationTypes.SET_USER_LOADING]: SET_USER_LOADING,
  [mutationTypes.SET_ACTIVE_ITEM_MENU]: SET_ACTIVE_ITEM_MENU,
  [mutationTypes.SET_ERROR]: SET_ERROR,
  [mutationTypes.SET_URL_LOGO]: SET_URL_LOGO,
  [mutationTypes.SET_PROFILES_BY_CLIENT_ID]: SET_PROFILES_BY_CLIENT_ID,
  [mutationTypes.SET_PASSWORD_GENERATED]: SET_PASSWORD_GENERATED,
  [mutationTypes.SET_USER_DATA_TO_EDIT]: SET_USER_DATA_TO_EDIT,
  [mutationTypes.SET_USER_UPDATED]: SET_USER_UPDATED,
  [mutationTypes.SET_USER_DELETED]: SET_USER_DELETED
};
