import * as mutationsTypes from "./mutations-types";

const SET_ALL_ZONES = (state, zones) => {
  state.allZones = zones;
};

const SET_PROJECT_SELEDTED = (state, project) => {
  state.projectSelected = project;
};

const SET_ZONE_LOADING = (state, loadingLinearProgress) => {
  state.zoneLoading = loadingLinearProgress;
};

const SET_ZONE_SELECTED = (state, zone) => {
  state.zoneSelected = zone;
};

const SET_NEW_SENSOR = (state, newsensor) => {
  state.listSensors.push(newsensor);
};
const SET_EDIT_SENSOR = (state, data) => {
  const sensorFound = state.listSensors.find((sensor) => sensor.id === data.id);
  if (sensorFound) {
    sensorFound.displayName = data.displayName;
    sensorFound.label = data.displayName;
    sensorFound.zoneId = data.zoneId;
    sensorFound.zoneName = data.zoneName;
    sensorFound.value = data.id;
    sensorFound.id = data.id;
  }
};

const SET_FILTER_ZONES = (state, zones) => {
  state.filterZones = zones;
};

const SET_LIST_ZONES_OPTIONS = (state, zones) => {
  state.listZonesOptions = zones;
};

export default {
  [mutationsTypes.SET_ALL_ZONES]: SET_ALL_ZONES,
  [mutationsTypes.SET_PROJECT_SELEDTED]: SET_PROJECT_SELEDTED,
  [mutationsTypes.SET_ZONE_LOADING]: SET_ZONE_LOADING,
  [mutationsTypes.SET_ZONE_SELECTED]: SET_ZONE_SELECTED,
  [mutationsTypes.SET_NEW_SENSOR]: SET_NEW_SENSOR,
  [mutationsTypes.SET_EDIT_SENSOR]: SET_EDIT_SENSOR,
  [mutationsTypes.SET_FILTER_ZONES]: SET_FILTER_ZONES,
  [mutationsTypes.SET_LIST_ZONES_OPTIONS]: SET_LIST_ZONES_OPTIONS
};
