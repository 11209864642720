import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { getCurrentUser } from "src/services/login";
import store from "../store";

Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function (/* { store, ssrContext } */) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,
    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
  });


  Router.beforeResolve(async (to, from, next) => {
    
    if(to.name === "new-password") {
      if (!to.query.mode || !to.query.oobCode || !to.query.apiKey) {
        next({ name: "login" });
      }
    }

    const userInfo = store.state.user.user;
    // redirect to login page if not logged in and trying to access a restricted page
    const currentUser = await getCurrentUser();

    if (to.matched.some((record) => record.meta.authorize)) {
      if (!currentUser || !userInfo.identityId) {
        // not logged in so redirect to login page with the return url
        next({ name: "login", query: { redirect: to.fullPath } });
      } else if (
        !userInfo.profile.permissions.some((permit) =>
          permit.code.includes(to.meta.code)
        )
      ) {
        next({ name: "error" })
      } else {
        next();
      }
    } else {
      next();
    }
  });
  return Router;
}
