import { queries, mutations } from  "src/services/cv-api/graphql";
import { Post } from "src/services/cv-api/utils"

export const listAllZones = async (variables) => {
  const body = {
    query: queries.listAllZones,
    variables
  }
  const { data } = await Post(body);
  return data;
};

