import posthog from "posthog-js";

posthog.init(process.env.POSTHOG_API, {
  api_host: process.env.POSTHOG_HOST,
});
posthog.identify('enviroment', {
  enviroment: process.env.PROJECT_ID,
})


export default posthog
