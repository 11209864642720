import { queries, mutations } from "src/services/cv-api/graphql";
import { Post } from "src/services/cv-api/utils";

export const getUserInfo = async (identityId) => {
  const body = {
    query: queries.getUserInfo,
    variables: {
      identityId,
    },
  };
  const { data } = await Post(body);
  return data;
};

export const getClientsByUser = async (userId, clientId) => {
  const body = {
    query: queries.getClientsByUser,
    variables: {
      userId,
      clientId,
    },
  };
  const { data } = await Post(body);
  return data;
};

export const createNewClient = async (client) => {
  const body = {
    query: mutations.createNewClient,
    variables: {
      client
    }
  };
  const { data } = await Post(body);
  return data
};

export const createNewUser = async (clientId, projectId, user) => {
  const body = {
    query: mutations.createNewUser,
    variables: {
      clientId,
      projectId,
      user
    }
  };
  const { data } = await Post(body);
  return data
};

export const createNewProject = async (clientId, project) => {
  const body = {
    query: mutations.createNewProject,
    variables: {
      clientId,
      project
    }
  };
  const { data } = await Post(body);
  return data
};

export const getProfilesByClientId = async (clientId) => {
  const body = {
    query: queries.getProfilesByClientId,
    variables: {
      clientId
    },
  };
  const { data } = await Post(body);
  return data;
};

export const updateLastSignIn = async (userId) => {
  const body = {
    query: mutations.updateLastSignIn,
    variables: {
      userId
    }
  };
  const { data } = await Post(body);
  return data;
};

export const updateUser = async (user) => {
  const body = {
    query: mutations.updateUser,
    variables: {
      user
    }
  };
  const { data } = await Post(body);
  return data;
};

export const deleteUser = async (userId) => {
  const body = {
    query: mutations.deleteUser,
    variables: {
      userId,
    },
  };

  const { data } = await Post(body);
  return data;
};
