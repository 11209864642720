export const FETCH_EVENTS_BY_ZONE_ID = "FETCH_EVENTS_BY_ZONE_ID";
export const FETCH_HISTORIC_EVENTS_BY_ZONE_ID = "FETCH_HISTORIC_EVENTS_BY_ZONE_ID";
export const CREATE_EVENT_COMMENT = "CREATE_EVENT_COMMENT";
export const UPDATE_STATUS_EVENT = "UPDATE_STATUS_EVENT";
export const GET_VIDEO_DOWNLOAD = "GET_VIDEO_DOWNLOAD";
export const LISTEN_EVENT_MANAGEMENT = "LISTEN_EVENT_MANAGEMENT";
export const UNLISTEN_EVENT_MANAGEMENT = "UNLISTEN_EVENT_MANAGEMENT";
export const FETCH_EVENTS_TYPES_BY_PROJECTS = "FETCH_EVENTS_TYPES_BY_PROJECTS";


