export default {
  user: {},
  projectSelected: {},
  clientSelected: {},
  clientsByUser: [],
  newClient: {},
  newUser: {},
  userLoading: false,
  activeItemMenu: "",
  error: false,
  urlLogo: {},
  profilesByClientId: [],
  passwordGenerated: "",
  userDataToEdit: {},
  userUpdated: "",
  userDeleted: false
};
