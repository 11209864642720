import * as getterTypes from "./getters-types";
import { sortItems } from "src/utils/functions";

const GET_TABS_EVENTS = (state, getter, rootState) => {
  let tabsToShow = [];
  const user = rootState.user.user.profile;
  const zone = rootState.zones.zoneSelected;

 if (zone.id === "all") {
    tabsToShow.push({
      value: "event-management",
      label: "Gestión de eventos",
      index: "1"
    },
    { value: "event-historic", label: "Historial de eventos" , index: "2"}
    );

    if (user) {
      user.permissions.map((permit) => {
        if (permit.code === "mapzones") {
          tabsToShow.push({ value: "event-map", label: "Mapa", index: "4" });
        }
      });
    }
  } else {
    tabsToShow.push(
      { value: "event-management", label: "Gestión de eventos", index: "1" },
      { value: "event-historic", label: "Historial de eventos" , index:"2"}
    );
    if (user) {
      user.permissions.map((permit) => {
        if (permit.code === "video") {
          tabsToShow.push({ value: "event-video", label: "Visualizador de video" , index: "3"});
        }
        if (permit.code === "map") {
          tabsToShow.push({ value: "event-map", label: "Mapa", index: "4" });
        }
      });
    }
  }
  sortItems(tabsToShow, "index")
  return tabsToShow;
};

const GET_EVENTS_TAKEN = (state) => {
  const eventsTaken = state.eventsTaken.filter((event) => event.taken === true);
  return eventsTaken
};

export default {
  [getterTypes.GET_TABS_EVENTS]: GET_TABS_EVENTS,
  [getterTypes.GET_EVENTS_TAKEN]: GET_EVENTS_TAKEN,
};
