import * as actionsTypes from "./actions-types";
import * as mutationsTypes from "./mutations-types";
import { listAllZones } from "src/services/cv-api/module/zones/zones.js";
import { twoDigitsNumber, sortAlphaNumeric } from "src/utils/functions";

const FETCH_ALL_ZONES = async (
  { state, commit, rootState },
  { clientId, projectId }
) => {
  const variables = { clientId, projectId };
  const filter = {
    object: "zones",
    values: { field: "id" }
  };
  let dataToCommit = [];
  let totalGlobalSensors = 0;
  let clientName = rootState.user.projectSelected.clientName;
  try {
    commit(mutationsTypes.SET_ZONE_LOADING, true);

    variables.sort = { field: "displayName", sort: "ASC" };
    if (state.filterZones.length > 0) {
      filter.values.operator = { eq: state.filterZones[0] };
      variables.filter = filter;
    }

    const zones = await listAllZones(variables);
    zones.AllZones.zones.map((el) => {
      totalGlobalSensors += el.totalSensors;
      dataToCommit.push({
        id: el.id,
        displayName: el.displayName,
        totalSensors: twoDigitsNumber(el.totalSensors),
        geo: el.geo,
        geoName: el.geo.displayName,
        img: `${process.env.API_STORAGE}/${process.env.STORAGE_BUCKET}/${process.env.ZONE_BUCKET}/${el.id}.jpg`,
        showLocation: false,
      });
    });

    sortAlphaNumeric(dataToCommit, "displayName");

    if(!filter.values.operator) {
      const zonesJoined = {
        id: "all",
        displayName: "Todas las zonas",
        totalSensors: twoDigitsNumber(totalGlobalSensors),
        geo: { displayName: clientName },
        geoName:  clientName,
        img: `${process.env.API_STORAGE}/${process.env.STORAGE_BUCKET}/${process.env.ZONE_BUCKET}/${zones.AllZones.zones[0].id}.jpg`,
        showLocation: true,
      };

      dataToCommit.splice(0, 0, zonesJoined);
    }

  } catch (error) {
    console.log("error DATA ZONES");
    commit(mutationsTypes.SET_ZONE_LOADING, false);
  } finally {
    filter.values.operator ?
    commit(mutationsTypes.SET_ZONE_SELECTED, dataToCommit[0]) :
    commit(mutationsTypes.SET_ALL_ZONES, dataToCommit);
    commit(mutationsTypes.SET_ZONE_LOADING, false);
  }
};

const FETCH_LIST_ZONES = async (
  { state, commit, rootState },
  { clientId, projectId }
) => {
  const variables = { clientId, projectId };
  let dataToCommit = [];
  try {
    commit(mutationsTypes.SET_ZONE_LOADING, true);

    variables.sort = { field: "displayName", sort: "ASC" };
    const zones = await listAllZones(variables);

    zones.AllZones.zones.map((zone) => {
      dataToCommit.push({
        label: zone.displayName,
        value: zone.id,
        id: zone.id,
      });
    });

    sortAlphaNumeric(dataToCommit, "label");
  } catch (error) {
    console.log("error LIST ZONES");
    commit(mutationsTypes.SET_ZONE_LOADING, false);
  } finally {
    commit(mutationsTypes.SET_LIST_ZONES_OPTIONS, dataToCommit);
    commit(mutationsTypes.SET_ZONE_LOADING, false);
  }
};

export default {
  [actionsTypes.FETCH_ALL_ZONES]: FETCH_ALL_ZONES,
  [actionsTypes.FETCH_LIST_ZONES]: FETCH_LIST_ZONES
};
