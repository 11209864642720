export default `
mutation UpdateRoi($roi: RoiInput!, $sensorId: String!) {
    UpdateRoi(roi: $roi, sensorId: $sensorId) {
      id
      displayName
      eventTypes
      points {
        x
        y
      }
      color
      sensorId
      eventTypeId
    }
  }
`;