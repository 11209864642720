export const SET_USER = "SET_USER";
export const SET_UPDATE_USER_BY_NEW_CLIENT = "SET_UPDATE_USER_BY_NEW_CLIENT";
export const SET_UPDATE_USER_BY_NEW_PROJECT = "SET_UPDATE_USER_BY_NEW_PROJECT";
export const SET_PROJECT_SELECTED = "SET_PROJECT_SELECTED";
export const SET_CLIENTS_BY_USER = "SET_CLIENTS_BY_USER";
export const SET_NEW_CLIENT = "SET_NEW_CLIENT";
export const SET_NEW_USER = "SET_NEW_USER";
export const SET_CLIENT_SELECTED = "SET_CLIENT_SELECTED";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_ACTIVE_ITEM_MENU = "SET_ACTIVE_ITEM_MENU";
export const SET_ERROR = "SET_ERROR";
export const SET_URL_LOGO = "SET_URL_LOGO";
export const SET_PROFILES_BY_CLIENT_ID = "SET_PROFILES_BY_CLIENT_ID";
export const SET_PASSWORD_GENERATED = "SET_PASSWORD_GENERATED";
export const SET_USER_DATA_TO_EDIT = "SET_USER_DATA_TO_EDIT";
export const SET_USER_UPDATED = "SET_USER_UPDATED";
export const SET_USER_DELETED = "SET_USER_DELETED";
