import { queries, mutations } from "src/services/cv-api/graphql";
import { Post } from "src/services/cv-api/utils";

export const getRois = async (sensorId) => {
  const body = {
    query: queries.getRois,
    variables: {
      sensorId,
    },
  };
  const { data } = await Post(body);
  return data;
};

export const createNewRoi = async (roi, sensorId) => {
  const body = {
    query: mutations.createNewRoi,
    variables: {
      sensorId,
      roi,
    },
  };
  const { data } = await Post(body);
  return data;
};

export const updateRoi = async (roi, sensorId) => {
  const body = {
    query: mutations.updateRoi,
    variables: {
      roi,
      sensorId,
    },
  };

  const { data } = await Post(body);
  return data;
};

export const deleteRoi = async (roiId) => {
  const body = {
    query: mutations.deleteRoi,
    variables: {
      roiId,
    },
  };

  const { data } = await Post(body);
  return data;
};

export const getAllSensorsByProject = async (variables) => {
  const body = {
    query: queries.getAllSensorsByProject,
    variables
  };

  const { data } = await Post(body);
  return data;
};
