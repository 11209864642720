export default `
mutation CreateRoi($roi: RoiInput!, $sensorId: String!) {
  CreateRoi(roi: $roi, sensorId: $sensorId) {
    id
    displayName
    eventTypes
    points {
      x
      y
    }
    color
    sensorId
    eventTypeId
  }
} 
`