import * as actionsTypes from "./actions-types";
import * as mutationsTypes from "./mutations-types";
import {
  listAllProcessedEvents,
  CreateProcessedComment,
  updateProcessedEventStatus,
  getVideo,
  getEventsTypesByProjects
} from "src/services/cv-api/module/events/event.js";
import { date } from "quasar";
import { firestore } from "src/boot/firebase";
import { collection, doc, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { datesFormat } from "src/utils/functions";
import { texts_alert_download_video } from "src/utils/texts";
import { errorAlert } from "src/utils/notifies";

let unsubscribe = null;

const FETCH_EVENTS_BY_ZONE_ID = async (
  { commit, state },
  { clientId, projectId, zoneId }
) => {
  let dataToCommit;
  const sort = {
    field: "timestamp",
    order: "DESC",
  };
  const filter = {};
  const from = (state.pagination.pageNumber - 1) * state.pagination.rowsPerPage;
  const size = state.pagination.rowsPerPage;
  const pit = null;

  if (state.filterEventsToday.priority.length > 0) {
    filter.priorityName = { in: state.filterEventsToday.priority };
  }
  if (state.filterEventsToday.eventType.length > 0) {
    filter.type = { in: state.filterEventsToday.eventType };
  }
  if (state.filterEventsToday.status.length > 0) {
    filter.statusName = { in: state.filterEventsToday.status };
  }
  if (state.filterEventsToday.sensor.length > 0) {
    filter.sensorId = { in: state.filterEventsToday.sensor };
  }
  if (state.filterEventsToday.zones.length > 0 || zoneId) {
    zoneId
      ? (filter.zoneId = { in: [zoneId] })
      : (filter.zoneId = { in: state.filterEventsToday.zones });
  }

  const aggregations = {
    aggregationInputs: [
      { bucket: "status", field: "statusName.keyword" },
      { bucket: "type", field: "type.keyword" },
    ],
  };

  try {
    const eventsFound = await listAllProcessedEvents(
      clientId,
      projectId,
      sort,
      from,
      pit,
      size,
      aggregations,
      filter
    );

    if (state.allEventsByZoneId.length !== 0) {
      dataToCommit = state.allEventsByZoneId;
    } else {
      dataToCommit = eventsFound.AllProcessedEvents.processedEvents;
    }

    commit(mutationsTypes.SET_COUNTER_AGGREGATIONS, {
      status:
        eventsFound.AllProcessedEvents.queryAggregationOutputs[0]
          .aggregationResponse,
      types:
        eventsFound.AllProcessedEvents.queryAggregationOutputs[1]
          .aggregationResponse,
    });
    if (dataToCommit.length !== 0) {
      dataToCommit = eventsFound.AllProcessedEvents.processedEvents;
    }

    if (eventsFound.AllProcessedEvents.total !== state.dataEvents.total) {
      commit(mutationsTypes.SET_DATA_EVENTS, {
        total: eventsFound.AllProcessedEvents.total,
        pit: eventsFound.AllProcessedEvents.pit,
      });
    }
  } catch (error) {
    console.log("ERROR EVENTS");
  } finally {
    commit(mutationsTypes.SET_EVENTS_LOADING, false);
    commit(mutationsTypes.SET_EVENTS_BY_ZONE_ID, dataToCommit);
  }
};

const FETCH_HISTORIC_EVENTS_BY_ZONE_ID = async (
  { commit, state },
  { clientId, projectId, zoneId }
) => {
  let dataToCommit;
  const sort = {
    field: "timestamp",
    order: "DESC",
  };
  const filter = {};
  const from = (state.pagination.pageNumber - 1) * state.pagination.rowsPerPage;
  const size = state.pagination.rowsPerPage;
  const pit = null;
  let startDate = "";
  let endDate = "";
  let maxDate = "";
  let minDate = "";

  if (state.filterEventsHistoric.priority.length > 0) {
    filter.priorityName = { in: state.filterEventsHistoric.priority };
  }
  if (state.filterEventsHistoric.eventType.length > 0) {
    filter.type = { in: state.filterEventsHistoric.eventType };
  }
  if (state.filterEventsHistoric.status.length > 0) {
    filter.statusName = { in: state.filterEventsHistoric.status };
  }
  if (state.filterEventsHistoric.sensor.length > 0) {
    filter.sensorId = { in: state.filterEventsHistoric.sensor };
  }
  if (state.filterEventsHistoric.zones.length > 0 || zoneId) {
    zoneId
      ? (filter.zoneId = { in: [zoneId] })
      : (filter.zoneId = { in: state.filterEventsHistoric.zones });
  }
  if (
    state.filterEventsHistoric.dateStart &&
    state.filterEventsHistoric.dateEnd
  ) {
    startDate = datesFormat(state.filterEventsHistoric.dateStart);
    minDate = new Date(startDate).toISOString();

    endDate = datesFormat(state.filterEventsHistoric.dateEnd);
    maxDate = new Date(endDate).toISOString();

    filter.timestamp = {
      between: {
        max: maxDate,
        min: minDate,
      },
    };
  }

  const aggregations = {
    aggregationInputs: [
      { bucket: "status", field: "statusName.keyword" },
      { bucket: "type", field: "type.keyword" },
    ],
  };

  try {
    const eventsFound = await listAllProcessedEvents(
      clientId,
      projectId,
      sort,
      from,
      pit,
      size,
      aggregations,
      filter
    );

    dataToCommit = eventsFound.AllProcessedEvents.processedEvents;

    if (
      eventsFound.AllProcessedEvents.total !== state.dataEventsHistoric.total
    ) {
      commit(mutationsTypes.SET_DATA_EVENTS_HISTORIC, {
        total: eventsFound.AllProcessedEvents.total,
        pit: eventsFound.AllProcessedEvents.pit,
      });
    }
  } catch (error) {
    console.log("ERROR HISTORIC EVENTS");
  } finally {
    commit(mutationsTypes.SET_HISTORIC_EVENTS_BY_ZONE_ID, dataToCommit);
    commit(mutationsTypes.SET_EVENTS_LOADING, false);
  }
};

const CREATE_EVENT_COMMENT = async (
  { commit, state },
  { clientId, projectId, newComment }
) => {
  const where = {
    id: {
      eq: "",
    },
  };
  const set = {
    comment: {
      id: "",
      message: "",
      timestamp: "",
      userId: "",
      userName: "",
    },
  };

  const getTimeZoneOffsetChile =
    newComment.newComment.timestamp.getTimezoneOffset();
  const addChileTimeZone = date.subtractFromDate(
    newComment.newComment.timestamp,
    {
      minutes: getTimeZoneOffsetChile,
    }
  );

  where.id.eq = newComment.newComment.eventId;
  set.comment = {
    message: newComment.newComment.message,
    timestamp: addChileTimeZone,
    userId: newComment.newComment.userId,
    userName: newComment.newComment.userName,
  };

  try {
    const addedComment = await CreateProcessedComment(
      clientId,
      projectId,
      where,
      set
    );
    commit(
      mutationsTypes.SET_NEW_COMMENT,
      addedComment.CreateProcessedEventComment
    );
    if (state.historicsEventsByZoneId) {
      commit(
        mutationsTypes.SET_NEW_COMMENT_HISTORIC,
        addedComment.CreateProcessedEventComment
      );
    }
  } catch (error) {
    console.log("ERROR NEW COMMENT");
  }
};

const UPDATE_STATUS_EVENT = async (
  { commit, state },
  { clientId, projectId, eventUpdated }
) => {
  const where = {
    id: {
      eq: "",
    },
  };
  const set = {
    statusName: "",
  };

  where.id.eq = eventUpdated.eventUpdated.id;
  set.statusName = eventUpdated.eventUpdated.statusName;
  try {
    const updatedStatus = await updateProcessedEventStatus(
      clientId,
      projectId,
      where,
      set
    );
    commit(
      mutationsTypes.SET_EVENTS_STATUS,
      updatedStatus.UpdateProcessedEventStatus
    );
    if (state.historicsEventsByZoneId) {
      commit(
        mutationsTypes.SET_EVENTS_STATUS_HISTORIC,
        updatedStatus.UpdateProcessedEventStatus
      );
    }
  } catch (error) {
    console.log("ERROR UPDATE STATUS");
  }
};

const GET_VIDEO_DOWNLOAD = async (
  { commit, state },
  { clientId, projectId, settingsVideo, videoToDownload }
) => {
  const mutationLoading = !videoToDownload
    ? mutationsTypes.SET_LOADING_TIMELAPSE
    : mutationsTypes.SET_DONWLOADING_VIDEO;
  commit(mutationLoading, true);
  let urlVideo = "";
  try {
    if (!videoToDownload || videoToDownload === "original") {
      const getUrl = await getVideo(clientId, projectId, settingsVideo);
      !videoToDownload
        ? commit(mutationsTypes.SET_URL_TIMELAPSE, getUrl.GetVideo.url)
        : (urlVideo = getUrl.GetVideo.url);
    } else urlVideo = state.urlTimelapse;

    if (videoToDownload) {
      urlVideo === "" || urlVideo === null
        ? errorAlert(texts_alert_download_video[videoToDownload])
        : window.open(`${urlVideo}`, "_blank");
    }
  } catch (error) {
    if (videoToDownload) {
        errorAlert(texts_alert_download_video[videoToDownload]);
    }
    console.log("ERROR VIDEO");
  } finally {
    commit(mutationLoading, false);
  }
};
const LISTEN_EVENT_MANAGEMENT = async ({ commit, state }, { cid }) => {
  const newDate = new Date();
  const dateBeforeBySeconds = date.subtractFromDate(newDate, { seconds: 5 });

  const activityOnClientsRef = collection(firestore, 'activity-on-clients');
  const activityOnEventsQuery = query(
    collection(activityOnClientsRef,  cid, 'activity-on-events'),
    orderBy('timestamp'),
    where('timestamp', '>=', dateBeforeBySeconds) // <-- Use where to specify the startAt condition
  );

  unsubscribe = onSnapshot(activityOnEventsQuery, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const { type, doc } = change;

      if (type === 'added') {
        commit(mutationsTypes.SET_EVENTS_TAKEN, {
          id: doc.id,
          user: doc.data().identity_id,
          taken: doc.data().taken,
          username: doc.data().username,
          timestamp: doc.data().timestamp,
        });
      }
      if (type === 'modified') {
        commit(mutationsTypes.SET_UPDATE_EVENTS_TAKEN, {
          id: doc.id,
          user: doc.data().identity_id,
          taken: doc.data().taken,
          username: doc.data().username,
          timestamp: doc.data().timestamp,
        });
      }
      if (type === 'removed') {
        // Handle the 'removed' case if needed
      }
    });
  });
};

const UNLISTEN_EVENT_MANAGEMENT = async ({ commit, state }) => {
  if (unsubscribe) {
    unsubscribe();
  }
};

const FETCH_EVENTS_TYPES_BY_PROJECTS = async ({ commit, state }, {identityId, projectId}) => {
  try {
    const eventTypes = await getEventsTypesByProjects(identityId, projectId)
    commit(mutationsTypes.SET_EVENT_TYPES_BY_PROJECT, eventTypes.GetProjectsWithEventsSetToShow.eventTypes)

  } catch (error) {
    commit(mutationsTypes.SET_EVENT_TYPES_BY_PROJECT, [])
  }
}

export default {
  [actionsTypes.FETCH_EVENTS_BY_ZONE_ID]: FETCH_EVENTS_BY_ZONE_ID,
  [actionsTypes.FETCH_HISTORIC_EVENTS_BY_ZONE_ID]:
    FETCH_HISTORIC_EVENTS_BY_ZONE_ID,
  [actionsTypes.CREATE_EVENT_COMMENT]: CREATE_EVENT_COMMENT,
  [actionsTypes.UPDATE_STATUS_EVENT]: UPDATE_STATUS_EVENT,
  [actionsTypes.GET_VIDEO_DOWNLOAD]: GET_VIDEO_DOWNLOAD,
  [actionsTypes.LISTEN_EVENT_MANAGEMENT]: LISTEN_EVENT_MANAGEMENT,
  [actionsTypes.UNLISTEN_EVENT_MANAGEMENT]: UNLISTEN_EVENT_MANAGEMENT,
  [actionsTypes.FETCH_EVENTS_TYPES_BY_PROJECTS]: FETCH_EVENTS_TYPES_BY_PROJECTS
};
