export const texts_login = {
  restorePasswordTitle: "Recuperar contraseña",
  restorePasswordText:
    "Ingresa el correo electrónico asociado a la cuenta, te enviaremos las instrucciones para restablecer la contraseña",
  restorePasswordSuccesfull:
    "Hemos enviado un link para restaurar contraseña al mail ingresado. Si no lo visualizas en tu bandeja de entrada, por favor revisa tu casilla de spam",
  newPasswordCreatedSuccesfull:
    "¡Tu contraseña ha sido restablecida exitosamente!",
  linkExpired: "Esté link ha caducado :("
};

export const texts_rois = {
  confirmationDelete: {
    title: "Eliminar ROI",
    message:
      "Al dar click en Eliminar, no podrá recuperarlo posteriormente. ¿Está seguro que desea eliminar permanentemente el ROI seleccionado?.",
    action: "Eliminar",
  },
  confirmationEdit: {
    title: "¿Desea guardar los cambios?",
    message: "Si hace click en Guardar, no podrá deshacer los cambios.",
    action: "Guardar",
  },
};

export const texts_steps_create_user = {
  titleStep1: "Agrega un cliente y asigna un proyecto",
  titleStep2:  "Crea un usuario y asigna el perfil que corresponde"
};

export const texts_steps_edit_user = {
  titleStep1: "Edita cliente y asigna un proyecto",
  titleStep2:  "Edita un usuario y asigna el perfil que corresponde"
};

export const texts_user = {
  confirmationDelete: {
    title: "Eliminar Usuario",
    message:
      "¿Está seguro que desea eliminar el usuario? Importante: Esta acción es permanente.",
    action: "Eliminar",
    iconName: "fas fa-exclamation-triangle",
    iconColor: "warning"
  },
  successfulEdition: {
    title: "Usuario editado",
    message: "Se guardaron los cambios exitosamente!",
    action: "Aceptar",
    iconName: "far fa-check-circle",
    iconColor: "positive"
  },
  successfulRemoval: {
    title: "Eliminado",
    message: "¡El usuario ha sido eliminado exitosamente!",
    action: "Aceptar",
    iconName: "far fa-check-circle",
    iconColor: "positive"
  },
  error: {
    title: "Hubo un error",
    message: "Inténtalo nuevamente.",
    action: "Aceptar",
    iconName: "fas fa-exclamation-triangle",
    iconColor: "negative"
  }
};

export const texts_alert_download_video = {
  timelapse: "No hay timelapse disponible. Selecciona una nueva fecha.",
  original: "No hay video disponible. Selecciona una nueva fecha o rango."
}