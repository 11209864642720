import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const SecureStorage = new SecureLS({ encodingType: "aes" });

const PersistedState = createPersistedState({
  key: "data",
  paths: [
    "user",
    "events.dateStartSelected",
    "events.dateEndSelected",
    "events.filterEventsToday",
    "events.filterEventsHistoric",
    "events.alertSoundEvents",
    "user",
    "zones.zoneSelected",
    "settings",
  ],
  storage: {
    getItem: (key) => SecureStorage.get(key),
    setItem: (key, value) => SecureStorage.set(key, value),
    removeItem: (key) => SecureStorage.remove(key)
  },
});
export default PersistedState;
