export default `
query GetRois($eventType: String, $sensorId: String!) {
  GetRois(eventType: $eventType, sensorId: $sensorId) {
    id
    displayName
    eventTypes
    color
    sensorId
    eventTypeId
    points {
        x
        y
    }
  }
}
`
