export default `
query GetUserInfo($identityId: String!){
  GetUserInfo(identityId:$identityId){
		id
    displayName
    firstName
    lastName
    email
    identityId
    clients{
      id
      displayName
      rut
      projects{
        id
        displayName
        clientId
        geo{
          displayName
          lat
          lng
        }
        dashboards{
          url
          displayName
          order
        }
      }
      users{
        id
        profileId
        firstName
        lastName
        displayName
        email
        identityId
        root
        identityProvider
      }
      settings{
        eventStatuses{
          id
          displayName
          value
        }
        eventTagCommentsList{
          id
          displayName
          value
        }
        eventTypes{
          id
          displayName
          type
          timeAgregacion
          timeIntervalo
          priorityId
          incomingEventTypeId
          show
        }
        eventTypesPriorities{
          id
          displayName
          priority
          color
        }
      }
    }
    profile{
      id
      displayName
      permissions{
        id
        displayName
        code
      }
    }
    root
    identityProvider
    lastSignInTimestamp
  }
}
`;
