import { Notify } from "quasar";

export const errorAlert = (error) => {
  return Notify.create({
    timeout: 3500,
    position: "bottom",
    color: "red-7",
    message: error,
    icon: "error",
    textColor: "white",
  });
};

export const infoAlert = (info) => {
  return Notify.create({
    timeout: 2000,
    position: "bottom",
    color: "light-blue-5",
    message: info,
    icon: "report_problem",
    textColor: "white",
  });
};


export const successAlert = (info) => {
  return Notify.create({
    timeout: 2000,
    position: "bottom",
    color: "positive",
    message: info,
    icon: "check_circle",
    textColor: "white",
  });
};

export const successCenterAlert = (info) => {
  return Notify.create({
    timeout: 800,
    position: "center",
    color: "positive",
    message: info,
    icon: "check_circle",
    textColor: "white",
  });
};


